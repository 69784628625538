.workpage-container{
    min-height: calc(100vh - 164px);
    width: calc(100% - 20px);
    max-width:  var(--MAX_WIDTH);
    /* background: blue; */
}

.workpage-container > .logo{
    width: calc(100vw - 40px);
    max-width: 550px;
    /* height: calc(100vw - 40px);
    max-height: 550px; */
    object-fit: cover;
    border-radius: 1rem;
    border: thin solid rgb(200, 200, 200, 0.5);
}
.workpage-container > .link{
    margin-bottom: 20px;
}
.workpage-container > .link > a{
    text-decoration: none;
    color: white;
}
.workpage-container > .title{
    width: calc(100% - 20px);
    max-width: var(--MAX_WIDTH);
    text-align: center;
}
.workpage-container > .subtitle{
    width: calc(100% - 20px);
    max-width: var(--MAX_WIDTH);
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
}
.workpage-container > .description{
    width: calc(100% - 20px);
    height: auto;
    max-width: var(--MAX_WIDTH);
    text-align: center;
    margin-bottom: 20px;
}
.workpage-container > .description b{
    background-color: black;
    background-image: var(--MAIN_BACKGROUND);
    background-size: 100%;
    background-repeat: repeat;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    font-weight: 800;
    /* letter-spacing: 0.1em; */
}
.workpage-container > .description a{
    color: white;
    text-decoration: none;
    font-size: 0.8em;
    padding: 0px 10px;
}



.workpage-container > .gtitle{
    width: 100%;
    margin-bottom: 20px;
    margin-top: 50px;
}
.workpage-container > .gtitle > span{
    font-size: 1.2em;
    border-bottom: 3px solid rgba(200, 200, 200, 0.5);
    color: grey;
    padding: 0px 10px 10px 10px;
    text-align: center;
}














.phoneUI-con{
    width: calc(50% - 20px);
    position: relative;
    transition: width .2s;
}

.phoneUI-con > .phoneui-svg{
    width: 100%;
    z-index: 3;
}

.phoneUI-con > .phoneui-svg > .st0{fill:#DDDDDD;}
.phoneUI-con > .phoneui-svg > .st1{fill:#777777;}
.phoneUI-con > .phoneui-svg > .st2{
    fill: none;
    stroke:#777777;
    stroke-width:3;
    stroke-miterlimit:10;
    cursor: pointer;
}

.phoneUI-con:hover  > .phoneui-svg > .indicator{
    fill: var(--GREEN_PALETTE);
}
.phoneUI-con > .phoneui-svg > .st2:hover{
    fill:rgb(200, 200, 200);
}

.phoneUI-con > .phoneui-svg > .power{
    cursor: pointer;
    padding: 10px;
}

.phoneUI-con > .img{
    position: absolute;
    top: 4%;
    height: calc(100% - 11.6%);
    max-height: calc(100% - 11.6%);
    width: calc(100% - 6.5%);
    /* object-fit: cover; */
    z-index: 5;
    background-color: white;
}
.phoneUI-con > .img::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.phoneUI-con > .img > img{
    width: 100%;
    /* border-radius: 25px; */
}

.phoneUI-con > .screen{
    position: absolute;
    top: 4%;
    height: calc(100% - 11.6%);
    max-height: calc(100% - 11.6%);
    width: calc(100% - 6.5%);
    /* object-fit: cover; */
    z-index: 6;
    background-color: rgb(39, 39, 39);
}
.phoneUI-con > .screen.hidden{
    display: none;
}
.phoneUI-con > .screen.visible ~ svg{
    z-index: 10;
}


.compUI-con{
    width: calc(100% - 20px);
    position: relative;
    transition: width .2s;
    margin-bottom: 20px;
}
.compui-svg{
    width: 100%;
    z-index: 3;
}
.compui-svg > .st0{fill:#DDDDDD;}
.compui-svg > .st1{fill:#A55959;}
.compui-svg > .st2{fill:#777777;}
.compui-svg > .st3{fill:#A5A559;}
.compUI-con:hover > .compui-svg > .st2{
    fill:#7FA559;
}

.compUI-con > .img{
    position: absolute;
    top: 4.6%;
    height: calc(100% - 4.8%);
    max-height: calc(100% - 4.8%);
    width: calc(100% - 0.5%);
    z-index: 5;
    overflow-y: auto;
}
.compUI-con > .img::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}
.compUI-con > .img > img{
    width: 100%;
}



.docUI{
    width: calc(100% - 30px);
    max-width: var(--MAX_WIDTH);
    height: 550px;
    /* margin-top: 20px; */
}














.wp-phone-single{
    width: 100%;
    /* background-color: red; */
    margin-bottom: 20px;
}
.wp-phone-double{
    width: 100%;
    margin-bottom: 20px;
}
.wp-phone-double > div{
    margin: 0px 10px;
}

.wp-phone-triple{
    width: 100%;
    margin-bottom: 20px;
}
.wp-phone-triple > div{
    margin: 0px 5px;
}






.wp-comp-double{
    width: 100%;
    margin-bottom: 20px;
}
.wp-comp-double > div{
    margin: 0px 10px;
}
.wp-comp-double > div:first-child.active{
    z-index: 2;
}
.wp-comp-double > div:first-child.inactive{
    z-index: 1;
}
.wp-comp-double > div:last-child.active{
    z-index: 2;
}
.wp-comp-double > div:last-child.inactive{
    z-index: 1;
}


.wp-comp-double > div.inactive:last-child{
    margin-left: -100px;
}

.wp-comp-double > div.inactive:first-child{
    margin-right: -100px;
}



.wp-comp-triple{
    width: 100%;
    margin-bottom: 20px;
}

.wp-comp-triple > div:nth-child(1).active{
    z-index: 4;
}
.wp-comp-triple > div:nth-child(1).inactive{
    z-index: 2;
}

.wp-comp-triple > div:nth-child(2).active{
    z-index: 4;
}
.wp-comp-triple > div:nth-child(2).inactive{
    z-index: 3;
}

.wp-comp-triple > div:nth-child(3).active{
    z-index: 4;
}
.wp-comp-triple > div:nth-child(3).inactive{
    z-index: 1;
}



.wp-comp-triple > div.inactive:nth-child(1){
    margin-right: -100px;
}
.wp-comp-triple > div.inactive:nth-child(2){
    margin-right: -100px;
}
.wp-comp-triple > div.inactive:nth-child(3){
    margin-left: -100px;
}



.wp-phonecomp-single{
    width: 100%;
    margin-bottom: 20px;
}













.workpage-container > span{
    padding: 0px 10px;
    text-align: center;
}

.workpage-container > .tech {
    width: calc(100% - 20px);
    max-width: var(--MAX_WIDTH);
    /* background-color: blue; */

}
.workpage-container > .tech > .widget{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    /* width: var(--MAX_WIDTH); */
    margin-top: 10px;
}

.workpage-container > .tech > .widget > .t{
    min-width: 100px;
    width: calc(50% - 10px);
    height: 100%;
    margin-right: 10px;
    text-align: end;
    /* background-color: red; */
}
/* .workpage-container > .tech > .widget  > .t > span{
    padding: 0px;
    margin: 0px;
} */
.workpage-container > .tech > .widget  > .t >  p{
    font-size: 0.8em;
    padding: 0px;
    margin: 0px;
}
.workpage-container > .tech > .widget > .img{
    max-height: 100px;
    width: calc(50% - 10px);
    margin-left: 10px;
}
.workpage-container > .tech > .widget .img > img{
    max-height: 100px;
    width: 150px;
    object-fit: contain;
    /* background-color: red; */
}























@media (max-width: 767px) { /* mobile */
    .wp-comp-double > div.inactive:last-child{
        margin-left: -40px;
    }
    
    .wp-comp-double > div.inactive:first-child{
        margin-right: -40px;
    }

    .wp-comp-triple > div.inactive:nth-child(1){
        margin-right: -40px;
    }
    .wp-comp-triple > div.inactive:nth-child(2) ~ div.active:nth-child(1){
        margin-right: -40px;
    }
    .wp-comp-triple > div.inactive:nth-child(2) ~ div.active:nth-child(2){
        margin-left: -40px;
    }
    .wp-comp-triple > div.inactive:nth-child(3){
        margin-left: -40px;
    }
    
    
}