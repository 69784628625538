.footer-wrapper{
    width: calc(100vw - 20px);
    /* background: red; */
}

.footer-container{
    /* background: red; */
    width: calc(100% - 60px);
    padding: 20px 10px;
    max-width: var(--MAX_WIDTH);
}

.footer-container > svg{
    height: 20px;
    width: auto;
    /* background: red; */
    cursor: pointer;
    padding: 5px 10px;
}
.footer-container > .resume{
    width: auto;
    height: 30px;
    /* background: red; */
    padding: 5px 10px;
    font-size: 1em;
    font-family: avant-garde-md;
    cursor: pointer;
    font-family: avant-garde-lg;
    color: #344CB7;
}
.footer-container > .resume svg{
    height: 20px;
    width: 20px; 
    margin-right: 10px;
    fill: #344CB7;
}
.footer-container > span{
    font-size: 0.9em;
    white-space: nowrap;
}











@media (max-width: 767px) { /* mobile */
    
    .footer-container{
        /* background: red; */
        width: calc(100% - 60px);
        padding: 10px 10px;
        max-width: var(--MAX_WIDTH);
    }

    .footer-container > svg{
        height: 30px;
        width: auto;
        width: 30px;
        min-width: 30px;


        /* background: red; */
        cursor: pointer;
        padding: 5px 10px;
    }

    .footer-container > .resume{
        width: auto;
        height: 30px;
        /* background: red; */
        padding: 5px 10px;
        font-size: 1em;
        font-family: avant-garde-md;
        cursor: pointer;
        font-family: avant-garde-lg;
        color: #344CB7;
    }
    .footer-container > .resume svg{
        height: 30px;
        width: 30px; 
        min-width: 30px;
        margin-right: 10px;
        fill: #344CB7;
    }
    .footer-container > span{
        font-size: 0.8em;
        white-space: nowrap;
        align-self: flex-end;
    }
    
  
}